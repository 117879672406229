<template>
  <div>
    <v-card outlined>
      <v-card-title class="d-flex align-center">
        <span class="ma-0 pa-0"
          >{{ reactivo ? "Modificar" : "Nuevo" }} reactivo</span
        >
        <v-spacer></v-spacer>
        <v-select
          v-model="tipoSeleccionado"
          :items="tiposPregunta"
          item-text="nombre"
          item-value="valor"
          label="Tipo de reactivo"
          outlined
          rounded
          dense
          style="width: 5%"
          :hint="getDescripcion"
          persistent-hint
          :disabled="loading"
          @change="tipoReactivoModificado"
        >
        </v-select>
      </v-card-title>
      <v-card-text>
        <span>Texto de la pregunta:</span>
        <v-spacer></v-spacer>
        <div v-if="errors.texto.length">
          <span v-for="error in errors.texto" :key="error" class="red--text">
            - {{ error }}
          </span>
        </div>
        <editor-texto
          ref="editorTexto"
          :texto="texto"
          contenido="encuesta"
          :idContenido="$route.params.idEncuesta"
          class="mt-2"
        ></editor-texto>
        <div v-if="requiereOpciones" class="requiere-opciones mt-5">
          <div class="mb-2">
            <span>Opciones:</span>
          </div>
          <div v-if="opciones.length" class="mt-2 mb-3">
            <draggable
              v-model="opciones"
              tag="div"
              handle=".handle"
              @change="modificarIndexOpcion"
            >
              <template v-for="(opcion, index) in opciones">
                <v-list-item :key="index">
                  <v-list-item-icon>
                    <v-btn icon style="cursor: move" class="handle"
                      ><v-icon>mdi-dots-grid</v-icon></v-btn
                    >
                    <v-radio disabled></v-radio>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-text-field
                      v-if="reactivo"
                      dense
                      v-model="opciones[index].texto"
                      :rules="[
                        (v) =>
                          !!v ||
                          'La opción requiere un valor, asigne uno o elimine esta opción.',
                      ]"
                    ></v-text-field>
                    <v-text-field
                      v-else
                      dense
                      v-model="opciones[index]"
                      :rules="[
                        (v) =>
                          !!v ||
                          'La opción requiere un valor, asigne uno o elimine esta opción.',
                      ]"
                    ></v-text-field>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon @click="removerOpcion(index)">
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </draggable>
          </div>
          <div class="flex">
            <div class="flex-input">
              <v-text-field
                v-model="opcionEscrita"
                outlined
                dense
                label="Nueva opción"
                :error-messages="errors.opcionEscrita"
                @keyup.enter="agregarOpcion()"
                @input="validarNuevaOpcion()"
              ></v-text-field>
            </div>
            <div class="flex-boton">
              <v-btn
                :disabled="!nuevaOpcionEsValida || loading"
                color="primary"
                outlined
                block
                @click="agregarOpcion()"
              >
                <v-icon class="mr-2">mdi-plus</v-icon>
                <span>Agregar</span>
              </v-btn>
            </div>
          </div>
        </div>
        <div v-if="requireRangoNumerico" class="mt-1 require-rango-numerico">
          <div class="mb-2">
            <span>Rango numérico:</span>
          </div>
          <v-row dense>
            <v-col cols="6">
              <v-text-field
                v-model="valorMinimo"
                type="number"
                label="Valor mínimo"
                outlined
                dense
                :error-messages="errors.valorMinimo"
                @input="validarValorMinimo()"
                @blur="validarValorMinimo"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="valorMaximo"
                type="number"
                label="Valor máximo"
                outlined
                dense
                :error-messages="errors.valorMaximo"
                @input="validarValorMaximo()"
                @blur="validarValorMaximo"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div v-if="requiereNiveles" class="mt-1 requiere-niveles">
          <div class="mb-2">
            <span>Niveles:</span>
          </div>
          <div v-if="niveles.length" class="mt-2 mb-3">
            <draggable
              v-model="niveles"
              tag="div"
              handle=".handle"
              @change="modificarIndexNivel"
            >
              <template v-for="(nivel, index) in niveles">
                <v-list-item :key="index">
                  <v-list-item-icon>
                    <v-btn icon style="cursor: move" class="handle"
                      ><v-icon>mdi-dots-grid</v-icon></v-btn
                    >
                    <v-radio disabled></v-radio>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-text-field
                      v-if="reactivo"
                      dense
                      v-model="niveles[index].texto"
                      :rules="[
                        (v) =>
                          !!v ||
                          'El nivel requiere un valor, asigne uno o elimine este nivel.',
                      ]"
                    ></v-text-field>
                    <v-text-field
                      v-else
                      dense
                      v-model="niveles[index]"
                      :rules="[
                        (v) =>
                          !!v ||
                          'El nivel requiere un valor, asigne uno o elimine este nivel.',
                      ]"
                    ></v-text-field>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon @click="removerNivel(index)">
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </draggable>
          </div>
          <div class="flex">
            <div class="flex-input">
              <v-text-field
                v-model="nivelEscrito"
                outlined
                dense
                label="Nuevo nivel"
                :error-messages="errors.nivelEscrito"
                @keyup.enter="agregarNivel()"
                @input="validarNuevoNivel()"
              ></v-text-field>
            </div>
            <div class="flex-boton">
              <v-btn
                :disabled="!nuevoNivelEsValido || loading"
                color="primary"
                outlined
                block
                @click="agregarNivel()"
              >
                <v-icon class="mr-2">mdi-plus</v-icon>
                <span>Agregar</span>
              </v-btn>
            </div>
          </div>
        </div>
        <div class="mt-5 d-flex align-center">
          <v-btn
            v-if="!reactivo"
            color="primary"
            dark
            small
            @click="guardarReactivo()"
            :loading="loading"
            :class="{ 'disable-events': loading }"
          >
            <v-icon small class="mr-2">mdi-content-save</v-icon>
            <span>Guardar reactivo</span>
          </v-btn>
          <v-btn
            v-else
            color="secondary"
            outlined
            small
            :disabled="loading"
            width="150"
            @click="cerrarModificacion"
            >{{ loading ? "Guardando..." : "Cerrar" }}</v-btn
          >
          <v-switch
            v-model="requerida"
            label="Pregunta requerida"
            dense
            color="primary"
            :disabled="loading"
            class="ml-2"
            @change="requeridaModificado"
          ></v-switch>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, sameAs } from "vuelidate/lib/validators";
import draggable from "vuedraggable";
import editorTexto from "../contenidos/editorTexto.vue";

import {
  crearReactivoEncuesta,
  modificarReactivoEncuesta,
  modificarOpcionReactivoEncuesta,
  agregarOpcionReactivoEncuesta,
  removerOpcionReactivoEncuesta,
  agregarNivelReactivoEncuesta,
  modificarNivelReactivoEncuesta,
  removerNivelReactivoEncuesta,
} from "./encuestas.service.js";

export default {
  mixins: [validationMixin],

  props: {
    reactivo: { type: Object, default: () => null },
  },

  components: {
    "editor-texto": editorTexto,
    draggable,
  },

  data: () => ({
    loading: false,
    reactivoSinModificar: null,
    tiposPregunta: [
      {
        nombre: "Abierta corta",
        valor: "abiertaCorta",
        descripcion:
          "Este reactivo admite una respuesta corta de un solo renglón.",
      },
      {
        nombre: "Abierta párrafo",
        valor: "abiertaParrafo",
        descripcion:
          "La respuesta es dada en forma de texto de más de un renglón.",
      },
      {
        nombre: "Opciones",
        valor: "opciones",
        descripcion: "Solo se puede seleccionar una de varias opciones.",
      },
      {
        nombre: "Casillas",
        valor: "casillas",
        descripcion:
          "Con este reactivo se selcciona 1 o más de varias opciones.",
      },
      {
        nombre: "Desplegable",
        valor: "desplegable",
        descripcion:
          "Solo se selecciona una de varias opciones. Ideal cuando las opciones son muchas.",
      },
      {
        nombre: "Rango numérico",
        valor: "rangoNumerico",
        descripcion: "Se otorga un puntaje a las opciones agregadas.",
      },
      {
        nombre: "Niveles",
        valor: "niveles",
        descripcion:
          "Se le relaciona a cada opción con uno de los niveles agregados.",
      },
    ],
    texto: "",
    tipoSeleccionado: "",
    opciones: [],
    opcionEscrita: "",
    niveles: [],
    nivelEscrito: "",
    valorMinimo: 0,
    valorMaximo: 10,
    requerida: false,
    errors: {
      texto: [],
      opcionEscrita: [],
      nivelEscrito: [],
      valorMinimo: [],
      valorMaximo: [],
    },
  }),

  validations: {
    opcionEscrita: { required },
    nivelEscrito: { required },
    valorMinimo: { required },
    valorMaximo: { required, igualAValorMinimo: sameAs("valorMinimo") },
  },

  computed: {
    ...mapGetters(["colors"]),

    getDescripcion() {
      if (this.tipoSeleccionado)
        return this.tiposPregunta.find(
          (tipo) => tipo.valor === this.tipoSeleccionado
        ).descripcion;
      return "";
    },

    requiereOpciones() {
      const reactivosConOpciones = [
        "opciones",
        "casillas",
        "desplegable",
        "rangoNumerico",
        "niveles",
      ];
      return reactivosConOpciones.indexOf(this.tipoSeleccionado) !== -1;
    },

    requireRangoNumerico() {
      return this.tipoSeleccionado == "rangoNumerico";
    },

    valorMinimoEsValido() {
      return !this.errors.valorMinimo.length;
    },

    valorMaximoEsValido() {
      return !this.errors.valorMaximo.length;
    },

    requiereNiveles() {
      return this.tipoSeleccionado === "niveles";
    },

    nuevaOpcionEsValida() {
      return !this.errors.opcionEscrita.length;
    },

    opcionesSonValidas() {
      if (this.opciones.indexOf("") !== -1) return false;
      return true;
    },

    nuevoNivelEsValido() {
      return !this.errors.nivelEscrito.length;
    },

    nivelesSonValidos() {
      if (this.niveles.indexOf("") !== -1) return false;
      return true;
    },

    reactivoEsValido() {
      if (this.errors.texto.length) return false;
      if (
        this.requiereOpciones &&
        (this.errors.opcionEscrita.length || !this.opcionesSonValidas)
      )
        return false;
      if (
        this.requiereNiveles &&
        (this.errors.nivelEscrito.length || !this.nivelesSonValidos)
      )
        return false;
      if (this.requireRangoNumerico) {
        if (!this.valorMinimoEsValido || !this.valorMaximoEsValido)
          return false;
      }
      return true;
    },
  },

  watch: {
    reactivo() {
      this.setReactivoData();
    },
  },

  mounted() {
    this.tipoSeleccionado = this.tiposPregunta[0].valor;
    if (this.reactivo) this.setReactivoData(this.reactivo);
  },

  methods: {
    setReactivoData() {
      this.reactivoSinModificar = JSON.parse(JSON.stringify(this.reactivo));
      this.tipoSeleccionado = this.reactivo.tipoPregunta;
      this.texto = this.reactivo.texto;
      this.requerida = this.reactivo.requerida;
      if (this.requiereOpciones) this.opciones = this.reactivo.opciones;
      if (this.requiereNiveles) this.niveles = this.reactivo.niveles;
      if (this.requireRangoNumerico) {
        this.valorMaximo = this.reactivo.rangoNumerico.numeroFinal;
        this.valorMinimo = this.reactivo.rangoNumerico.numeroInicial;
      }
    },

    validarNuevaOpcion() {
      let errors = [];
      this.$v.opcionEscrita.$touch();
      !this.$v.opcionEscrita.required &&
        errors.push("La nueva opción es requerida.");
      this.errors.opcionEscrita = errors;
    },

    async agregarOpcion() {
      this.validarNuevaOpcion();
      if (!this.nuevaOpcionEsValida) return;

      if (this.reactivo) await this.agregarOpcionReactivoExistente();
      else this.opciones = [...this.opciones, this.opcionEscrita];
      this.opcionEscrita = "";
    },

    async agregarOpcionReactivoExistente() {
      this.loading = true;

      try {
        const serverResponse = await agregarOpcionReactivoEncuesta(
          this.reactivo._id,
          {
            texto: this.opcionEscrita,
          }
        );
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.$emit("reactivoModificado", serverResponse.pregunta);
          return serverResponse.nuevaOpcion;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    async removerOpcion(indexAEliminar) {
      if (this.reactivo)
        await this.removerOpcionReactivoExistente(indexAEliminar);
      else
        this.opciones = this.opciones.filter(
          (e, index) => index !== indexAEliminar
        );
    },

    async removerOpcionReactivoExistente(indexAEliminar) {
      this.loading = true;

      try {
        const serverResponse = await removerOpcionReactivoEncuesta(
          this.reactivo._id,
          this.opciones[indexAEliminar]._id
        );
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.$emit("reactivoModificado", serverResponse.pregunta);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    validarValorMinimo() {
      let errors = [];
      this.$v.valorMinimo.$touch();
      !this.$v.valorMinimo.required &&
        errors.push("El valor mínimo es requerido.");
      this.errors.valorMinimo = errors;
    },

    validarValorMaximo() {
      let errors = [];
      this.$v.valorMaximo.$touch();
      !this.$v.valorMaximo.required &&
        errors.push("El valor máximo es requerido.");
      this.valorMaximo * 1 < this.valorMinimo * 1 &&
        errors.push("El valor máximo debe ser mayor que el valor mínimo.");
      this.errors.valorMaximo = errors;
    },

    validarNuevoNivel() {
      let errors = [];
      this.$v.nivelEscrito.$touch();
      !this.$v.nivelEscrito.required &&
        errors.push("El nuevo nivel es requerido.");
      this.errors.nivelEscrito = errors;
    },

    async agregarNivel() {
      this.validarNuevoNivel();
      if (!this.nuevoNivelEsValido) return;

      if (this.reactivo) await this.agregarNivelReactivoExistente();
      else this.niveles = [...this.niveles, this.nivelEscrito];
      this.nivelEscrito = "";
    },

    async agregarNivelReactivoExistente() {
      this.loading = true;

      try {
        const serverResponse = await agregarNivelReactivoEncuesta(
          this.reactivo._id,
          {
            texto: this.nivelEscrito,
          }
        );
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.$emit("reactivoModificado", serverResponse.pregunta);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    removerNivel(indexAEliminar) {
      if (this.reactivo) this.removerNivelReactivoExistente(indexAEliminar);
      else
        this.niveles = this.niveles.filter(
          (nivel, index) => index !== indexAEliminar
        );
    },

    async removerNivelReactivoExistente(indexAEliminar) {
      this.loading = true;

      try {
        const serverResponse = await removerNivelReactivoEncuesta(
          this.reactivo._id,
          this.niveles[indexAEliminar]._id
        );
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.$emit("reactivoModificado", serverResponse.pregunta);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    validarTextoReactivo() {
      let errors = [];
      const textoActual = this.$refs.editorTexto.obtenerTexto();
      if (!textoActual || textoActual === "<p></p>")
        errors = [...errors, "El texto del reactivo es requerido."];
      this.errors.texto = errors;
    },

    validarOpcionesReactivo() {
      let errors = [];
      if (!this.opciones.length)
        errors = [...errors, "Se requiere de al menos una opción."];
      this.errors.opcionEscrita = errors;
    },

    validarNiveles() {
      let errors = [];
      if (!this.niveles.length)
        errors = [...errors, "Se requiere de al menos un nivel."];
      this.errors.nivelEscrito = errors;
    },

    async guardarReactivo() {
      this.validarTextoReactivo();
      if (this.requiereOpciones) this.validarOpcionesReactivo();
      if (this.requiereNiveles) this.validarNiveles();
      if (this.requireRangoNumerico) {
        this.validarValorMinimo();
        this.validarValorMaximo();
      }
      if (!this.reactivoEsValido) return;

      const pregunta = {
        idSeccion: this.$route.params.idSeccion,
        texto: this.$refs.editorTexto.obtenerTexto(),
        tipoPregunta: this.tipoSeleccionado,
        requerida: this.requerida,
      };
      if (this.requiereOpciones) pregunta.opciones = this.opciones;
      if (this.requireRangoNumerico)
        pregunta.rangoNumerico = {
          numeroInicial: this.valorMinimo,
          numeroFinal: this.valorMaximo,
        };
      if (this.requiereNiveles) pregunta.niveles = this.niveles;

      this.loading = true;
      try {
        const serverResponse = await crearReactivoEncuesta(pregunta);
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.$emit("reactivoCreado", serverResponse.pregunta);
          this.vaciarFormulario();
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    vaciarFormulario() {
      this.opciones = [];
      this.niveles = [];
      this.valorMinimo = 0;
      this.valorMaximo = 10;
      this.$refs.editorTexto.resetearTexto();
    },

    async tipoReactivoModificado() {
      if (this.reactivo) {
        this.loading = true;

        try {
          const serverResponse = await modificarReactivoEncuesta(
            this.reactivo._id,
            { tipoPregunta: this.tipoSeleccionado }
          );
          this.loading = false;

          this.$validateResponse(serverResponse);
          if (!serverResponse.ok)
            this.$systemErrorMessage(serverResponse.mensaje);
          else this.$emit("reactivoModificado", serverResponse.pregunta);
        } catch (error) {
          this.loading = false;
          this.$appErrorMessage();
        }
      }
    },

    async modificarTextoReactivo(nuevoTexto, modificarReactivoActual = true) {
      this.loading = true;

      try {
        const serverResponse = await modificarReactivoEncuesta(
          this.reactivo._id,
          { texto: nuevoTexto }
        );
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.$emit("reactivoModificado", serverResponse.pregunta);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    async requeridaModificado() {
      if (this.reactivo) {
        this.loading = true;

        try {
          const serverResponse = await modificarReactivoEncuesta(
            this.reactivo._id,
            { requerida: this.requerida }
          );
          this.loading = false;

          this.$validateResponse(serverResponse);
          if (!serverResponse.ok)
            this.$systemErrorMessage(serverResponse.mensaje);
          else this.$emit("reactivoModificado", serverResponse.pregunta, false);
        } catch (error) {
          this.loading = false;
          this.$appErrorMessage();
        }
      }
    },

    modificarIndexOpcion(event) {
      if (this.reactivo) {
        const idOpcion = event.moved.element._id;
        const nuevoIndex = event.moved.newIndex;

        this.modificarOpcion(idOpcion, { nuevoIndex }, false);
      }
    },

    async modificarOpcion(idOpcion, data, modificarReactivoActual = true) {
      this.loading = true;

      try {
        const serverResponse = await modificarOpcionReactivoEncuesta({
          idReactivo: this.reactivoSinModificar._id,
          idOpcion,
          data,
        });
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else
          this.$emit(
            "reactivoModificado",
            serverResponse.pregunta,
            modificarReactivoActual
          );
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    modificarIndexNivel(event) {
      if (this.reactivo) {
        const idNivel = event.moved.element._id;
        const nuevoIndex = event.moved.newIndex;

        this.modificarNivel(idNivel, { nuevoIndex }, false);
      }
    },

    async modificarNivel(idNivel, data, modificarReactivoActual = true) {
      this.loading = true;

      try {
        const serverResponse = await modificarNivelReactivoEncuesta({
          idReactivo: this.reactivoSinModificar._id,
          idNivel,
          data,
        });
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else
          this.$emit(
            "reactivoModificado",
            serverResponse.pregunta,
            modificarReactivoActual
          );
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    async modificarRangoNumerico(modificarReactivoActual = true) {
      this.loading = true;

      try {
        const requestData = {
          rangoNumerico: {
            numeroInicial: this.valorMinimo,
            numeroFinal: this.valorMaximo,
          },
        };

        const serverResponse = await modificarReactivoEncuesta(
          this.reactivoSinModificar._id,
          requestData
        );
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else
          this.$emit(
            "reactivoModificado",
            serverResponse.pregunta,
            modificarReactivoActual
          );
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    async cerrarModificacion() {
      let indexOpcionesAModificar = [];
      let indexNivelesAModificar = [];

      let seModificoTextoReactivo = false;
      const textoEditor = this.$refs.editorTexto.obtenerTexto();
      if (textoEditor !== this.texto) seModificoTextoReactivo = true;

      const seModificoRangoNumerico =
        this.reactivoSinModificar.rangoNumerico &&
        (this.valorMinimo !==
          this.reactivoSinModificar.rangoNumerico.numeroInicial ||
          this.valorMaximo !==
            this.reactivoSinModificar.rangoNumerico.numeroFinal);

      for (let i = 0; i < this.opciones.length; i++) {
        const opcionExiste =
          this.reactivoSinModificar.opciones[i] && this.opciones[i];
        const esMismoId =
          this.reactivoSinModificar.opciones[i]._id === this.opciones[i]._id;
        const textoEsDistinto =
          this.reactivoSinModificar.opciones[i].texto !==
          this.opciones[i].texto;

        if (opcionExiste && esMismoId && textoEsDistinto)
          indexOpcionesAModificar.push(i);
      }

      for (let i = 0; i < this.niveles.length; i++) {
        const nivelExiste =
          this.reactivoSinModificar.niveles[i] && this.niveles[i];
        const esMismoId =
          this.reactivoSinModificar.niveles[i]._id === this.niveles[i]._id;
        const textoEsDistinto =
          this.reactivoSinModificar.niveles[i].texto !== this.niveles[i].texto;

        if (nivelExiste && esMismoId && textoEsDistinto)
          indexNivelesAModificar.push(i);
      }

      for (let i = 0; i < indexOpcionesAModificar.length; i++) {
        const opcionAModificar = indexOpcionesAModificar[i];
        const modificarReactivoActual =
          !indexNivelesAModificar.length &&
          !seModificoTextoReactivo &&
          i === indexOpcionesAModificar.length - 1 &&
          !seModificoRangoNumerico;

        await this.modificarOpcion(
          this.opciones[opcionAModificar]._id,
          { texto: this.opciones[opcionAModificar].texto },
          modificarReactivoActual
        );
      }

      for (let i = 0; i < indexNivelesAModificar.length; i++) {
        const nivelAModificar = indexNivelesAModificar[i];
        const modificarReactivoActual =
          !indexOpcionesAModificar.length &&
          !seModificoTextoReactivo &&
          i === indexNivelesAModificar.length - 1;

        await this.modificarNivel(
          this.niveles[nivelAModificar]._id,
          { texto: this.niveles[nivelAModificar].texto },
          modificarReactivoActual
        );
      }

      if (seModificoRangoNumerico)
        await this.modificarRangoNumerico(!seModificoTextoReactivo);

      if (seModificoTextoReactivo)
        await this.modificarTextoReactivo(textoEditor);
      this.$emit("cancelar");
    },
  },
};
</script>

<style lang="scss" scoped>
.requiere-opciones .flex,
.requiere-niveles .flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 5px;

  .flex-input {
    width: 80%;
  }

  .flex-boton {
    height: 100%;
    width: 20%;

    .v-btn {
      position: relative;
      top: 2px;
    }
  }
}
</style>
