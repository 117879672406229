<template>
  <div>
    <v-card v-if="!modificar && reactivoLocal" outlined>
      <v-card-text :class="{ 'pb-0': soloLectura }">
        <v-row dense>
          <v-col cols="12">
            <div class="d-flex justify-space-between align-start">
              <div class="ck-content" v-html="reactivoLocal.texto"></div>
              <v-menu v-if="permisoEscritura" bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(menuReactivoItem, index) in menuReactivo"
                    :key="index"
                    @click="menuAction(menuReactivoItem)"
                  >
                    <v-list-item-title>
                      <v-icon small class="mr-2">{{
                        menuReactivoItem.icon
                      }}</v-icon>
                      <span>{{ menuReactivoItem.text }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
          <v-col v-if="esReactivoTipo('abiertaCorta')" cols="12">
            <v-text-field
              label="Respuesta"
              :readonly="soloLectura"
              filled
              dense
            ></v-text-field>
          </v-col>
          <v-col v-if="esReactivoTipo('abiertaParrafo')" cols="12">
            <v-textarea
              label="Respuesta"
              :readonly="soloLectura"
              rows="3"
              dense
              filled
            ></v-textarea>
          </v-col>
          <v-col v-if="esReactivoTipo('opciones')" cols="12">
            <v-radio-group>
              <v-radio
                v-for="opcion in reactivoLocal.opciones"
                :key="opcion._id"
                :label="opcion.texto"
                :readonly="soloLectura"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col v-if="esReactivoTipo('casillas')" cols="12">
            <v-checkbox
              v-for="opcion in reactivoLocal.opciones"
              :key="opcion._id"
              :label="opcion.texto"
              :readonly="soloLectura"
              dense
            ></v-checkbox>
          </v-col>
          <v-col v-if="esReactivoTipo('desplegable')" cols="12">
            <v-select
              label="Respuesta"
              dense
              filled
              :items="reactivoLocal.opciones"
              item-text="texto"
              value="_id"
            ></v-select>
          </v-col>
          <v-col v-if="esReactivoTipo('rangoNumerico')" cols="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Opción
                    </th>
                    <th
                      v-for="item in getRangoNumerico"
                      :key="item"
                      class="text-center"
                    >
                      {{ item }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="opcion in reactivoLocal.opciones"
                    :key="opcion._id"
                  >
                    <td>{{ opcion.texto }}</td>
                    <td
                      v-for="casilla in getRangoNumerico"
                      :key="casilla"
                      class="text-center justify-center"
                      style="padding-right: 8px"
                    >
                      <div style="width: 100%" class="d-flex justify-center">
                        <v-radio :readonly="soloLectura"></v-radio>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col v-if="esReactivoTipo('niveles')" cols="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Opción
                    </th>
                    <th
                      v-for="item in reactivo.niveles"
                      :key="item._id"
                      class="text-center"
                    >
                      {{ item.texto }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="opcion in reactivo.opciones" :key="opcion._id">
                    <td>{{ opcion.texto }}</td>
                    <td
                      v-for="casilla in reactivo.niveles"
                      :key="casilla._id"
                      class="text-center justify-center"
                      style="padding-right: 8px"
                    >
                      <div style="width: 100%" class="d-flex justify-center">
                        <v-radio :readonly="soloLectura"></v-radio>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <crear-reactivo
      v-else
      :reactivo="reactivoLocal"
      @reactivoModificado="reactivoModificado"
      @cancelar="cerrarModificacion"
    ></crear-reactivo>
  </div>
</template>

<script>
import crearReactivoEncuesta from "./crearReactivoEncuesta";

export default {
  props: {
    reactivo: { type: Object, required: true },
    soloLectura: { type: Boolean, default: true },
    permisoEscritura: { type: Boolean, default: false },
  },

  components: {
    "crear-reactivo": crearReactivoEncuesta,
  },

  data: () => ({
    modificar: false,
    menuReactivo: [
      {
        text: "Modificar reactivo",
        action: "modificarReactivo",
        icon: "mdi-square-edit-outline",
      },
    ],
    reactivoLocal: null,
  }),

  computed: {
    getRangoNumerico() {
      let resultado = [];
      const { numeroInicial, numeroFinal } = this.reactivo.rangoNumerico;

      let i = numeroInicial;
      for (i; i <= numeroFinal; i++) resultado = [...resultado, i];

      return resultado;
    },
  },

  mounted() {
    this.reactivoLocal = this.reactivo;
  },

  methods: {
    esReactivoTipo(tipo) {
      return this.reactivoLocal.tipoPregunta === tipo;
    },

    menuAction(data) {
      switch (data.action) {
        case "modificarReactivo":
          this.modificar = true;
          break;
      }
    },

    reactivoModificado(reactivo, actualizarReactivo = true) {
      if (actualizarReactivo) this.reactivoLocal = reactivo;
      this.$emit("reactivoModificado", reactivo);
    },

    cerrarModificacion() {
      this.reactivoLocal = this.reactivo;
      this.modificar = false;
    },
  },
};
</script>

<style>
@import url("../../classes/ck-content.css");
</style>
