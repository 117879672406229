<template>
  <div>
    <v-container>
      <v-card rounded="lg">
        <v-toolbar dense color="secondary" dark flat class="text-h6">
          <v-btn
            icon
            @click="$router.push(`/encuestas/${seccion.idEncuesta._id}`)"
            ><v-icon>mdi-arrow-left</v-icon></v-btn
          >
          <v-spacer></v-spacer> {{ seccion ? seccion.idEncuesta.nombre : "" }}
          <v-spacer></v-spacer>
          <v-menu v-if="permisoEscritura" offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(menuSeccionItem, index) in menuSeccion"
                :key="index"
                @click="menuAction(menuSeccionItem)"
              >
                <v-list-item-title>
                  <v-icon small class="mr-2">{{ menuSeccionItem.icon }}</v-icon>
                  <span>{{ menuSeccionItem.text }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
        <v-container v-if="loading || !seccion">
          <loading-seccion></loading-seccion>
        </v-container>
        <v-container v-else>
          <v-card-title v-if="!mostrarModificarSeccion" class="py-1 px-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  :disabled="!prevSeccion"
                  @click="
                    $router.push(
                      `/encuestas/${seccion.idEncuesta._id}/seccion/${prevSeccion}`
                    )
                  "
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon>mdi-chevron-left</v-icon></v-btn
                >
              </template>
              <span>Sección anterior</span>
            </v-tooltip>
            <v-spacer></v-spacer> {{ seccion.nombre }} <v-spacer></v-spacer>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  :disabled="!nextSeccion"
                  @click="
                    $router.push(
                      `/encuestas/${seccion.idEncuesta._id}/seccion/${nextSeccion}`
                    )
                  "
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon>mdi-chevron-right</v-icon></v-btn
                >
              </template>
              <span>Sección siguiente</span>
            </v-tooltip>
          </v-card-title>
          <v-divider
            v-if="!mostrarModificarSeccion"
            class="mx-3 my-3"
          ></v-divider>
          <v-card-text class="pa-0 text--secondary">
            <v-container>
              <modificar-seccion
                v-if="mostrarModificarSeccion"
                :idSeccion="seccion._id"
                :mostrar="mostrarModificarSeccion"
                :descripcionActual="seccion.descripcion"
                :nombreActual="seccion.nombre"
                @cancelar="mostrarModificarSeccion = false"
                @seccionModificada="seccionModificada"
              ></modificar-seccion>
              <div
                v-else-if="
                  seccion.descripcion && seccion.descripcion !== '<p></p>'
                "
                v-html="seccion.descripcion"
                class="ck-content mt-2"
                style="overflow: hidden"
              ></div>
              <div v-else>
                <span>No se ha capturado descripción.</span>
              </div>
            </v-container>

            <div class="mt-5 px-3">
              <v-row>
                <v-col
                  cols="12"
                  v-for="(reactivo, index) in seccion.preguntas"
                  :key="reactivo._id"
                >
                  <reactivo-encuesta
                    :reactivo="reactivo"
                    :soloLectura="false"
                    :permisoEscritura="permisoEscritura"
                    class="mt-3"
                    @reactivoModificado="reactivoModificado($event, index)"
                  ></reactivo-encuesta>
                </v-col>
              </v-row>
            </div>

            <div v-if="permisoEscritura" class="mt-8 px-3">
              <crear-reactivo-encuesta
                @reactivoCreado="reactivoCreado"
              ></crear-reactivo-encuesta>
            </div>
          </v-card-text>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getSeccionEncuesta } from "./encuestas.service";
import loadingSeccion from "./loadingSeccion.vue";
import modificarSeccion from "./modificarSeccion.vue";
import reactivoEncuesta from "./reactivoEncuesta.vue";
import crearReactivoEncuesta from "./crearReactivoEncuesta.vue";
import { permisoMenuEscuela } from '../../helpers/utilerias';

export default {
  components: {
    "loading-seccion": loadingSeccion,
    "modificar-seccion": modificarSeccion,
    "reactivo-encuesta": reactivoEncuesta,
    "crear-reactivo-encuesta": crearReactivoEncuesta,
  },

  data: () => ({
    loading: true,
    seccion: null,
    permisoEscritura: false,
    menuSeccion: [
      {
        text: "Modificar nombre y descipción",
        action: "modificarNombre",
        icon: "mdi-square-edit-outline",
      },
    ],
    mostrarModificarSeccion: false,
    nextSeccion: "",
    prevSeccion: "",
  }),

  computed: {
    ...mapGetters(["sessionToken", "menu"]),
  },

  watch: {
    $route() {
      if (!permisoMenuEscuela("Encuestas")) this.$router.push("/dashboard");
      else this.getSeccionEncuesta();
    },

    sessionToken(value) {
      if (value){
        if (!permisoMenuEscuela("Encuestas")) this.$router.push("/dashboard");
        else this.getSeccionEncuesta();
      } 
    },
  },

  async mounted() {
    if (this.sessionToken) {
      if (!permisoMenuEscuela("Encuestas")) this.$router.push("/dashboard");
      else this.getSeccionEncuesta();
    }
    
  },

  methods: {
    menuAction(data) {
      switch (data.action) {
        case "modificarNombre":
          this.mostrarModificarSeccion = true;
          break;
      }
    },

    async getSeccionEncuesta() {
      this.loading = true;

      try {
        const serverResponse = await getSeccionEncuesta(
          this.$route.params.idEncuesta,
          this.$route.params.idSeccion
        );
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.seccion = serverResponse.seccion;
          this.permisoEscritura = serverResponse.permisoEscritura;
          this.nextSeccion = serverResponse.next;
          this.prevSeccion = serverResponse.prev;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    seccionModificada(seccion) {
      this.mostrarModificarSeccion = false;
      this.seccion = seccion;
    },

    reactivoCreado(nuevoReactivo) {
      this.seccion.preguntas.push(nuevoReactivo);
    },

    reactivoModificado(reactivo, index) {
      this.seccion.preguntas.splice(index, 1, reactivo);
    },
  },
};
</script>

<style>
@import url("../../classes/ck-content.css");
</style>
