<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <v-text-field
          filled
          dense
          v-model="nombreNuevo"
          label="Nuevo nombre"
          :disabled="loading"
          :error-messages="errors.nombreNuevo"
          @input="$v.nombreNuevo.$touch()"
          @blur="$v.nombreNuevo.$touch()"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <editor-texto
          ref="editorTexto"
          :texto="descripcionActual"
          contenido="encuesta"
          :idContenido="$route.params.idEncuesta"
        ></editor-texto>
      </v-col>
      <v-col cols="12">
        <div class="d-flex">
          <v-btn
            color="secondary"
            outlined
            class="mr-2"
            :disabled="loading"
            @click="$emit('cancelar')"
            >Cancelar</v-btn
          >
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading || !esValido"
            @click="submit()"
          >
            <v-icon small class="mr-2">mdi-content-save</v-icon>
            <span>Guardar</span>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import editorTexto from "../contenidos/editorTexto.vue";
import { modificarSeccionEncuesta } from "./encuestas.service";

export default {
  props: {
    mostrar: { type: Boolean, default: false },
    descripcionActual: { type: String, default: "" },
    nombreActual: { type: String, default: "" },
    idSeccion: { type: String, required: true },
  },

  mixins: [validationMixin],

  components: {
    "editor-texto": editorTexto,
  },

  data: () => ({
    loading: false,
    nombreNuevo: "",
  }),

  computed: {
    errors() {
      const errors = {
        nombreNuevo: [],
      };

      if (!this.$v.nombreNuevo.$dirty) return errors;
      !this.$v.nombreNuevo.required &&
        errors.nombreNuevo.push("Campo requerido.");

      return errors;
    },

    esValido() {
      if (this.errors.nombreNuevo.length) return false;
      return true;
    },
  },

  validations: {
    nombreNuevo: { required },
  },

  mounted() {
    this.setNombreActual();
  },

  methods: {
    setNombreActual() {
      if (this.nombreActual) this.nombreNuevo = this.nombreActual;
    },

    async submit() {
      this.$v.nombreNuevo.$touch();
      if (!this.esValido) return;
      this.loading = true;

      try {
        const requestData = {
          descripcion: this.$refs.editorTexto.obtenerTexto(),
          nombre: this.nombreNuevo,
        };

        const serverResponse = await modificarSeccionEncuesta(
          this.idSeccion,
          requestData
        );
        this.loading = false;

        this.$emit("seccionModificada", serverResponse.seccion);
      } catch (error) {
        this.loading = false;
        this.$notificarError(
          "Error intentando modificar la descripción de la sección."
        );
      }
    },
  },
};
</script>
